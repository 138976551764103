import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const ProductCard = (props) => {
    const {
        children,
        className = "",
        cardActions = false,
        withImage = false,
        contentClasses = "p-0",
        contentSx = {},
        ...rest
    } = props;

    return (
        <Card 
            raised={false}
            className={className}
            elevation={2}
            sx={{
                borderLeft: '1px solid lightgrey',
                borderRight: '1px solid lightgrey',
                minWidth:'100%',
                maxHeight:{
                    lg: 'fit-content',
                    md:'fit-content',
                    sm:'90%'
                },
                minHeight:{
                    lg: 'fit-content',
                    md: 'fit-content',
                    sm:'100%'
                },
            }}  
            {...rest}
        >
            {withImage}

            <CardContent className={contentClasses} sx={contentSx}>
                {children}
            </CardContent>

            {cardActions}
        </Card>
    )
}

export default ProductCard